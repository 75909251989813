import axios from "axios";
export const baseURL = process.env.REACT_APP_BASE_URL;

export const axiosInstance = axios.create({
  baseURL,
  headers: {
    "content-type": "application/json",
  },
  // headers: { "X-Custom-Header": "foobar" },
});
