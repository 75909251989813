import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import queryString from "query-string";
import { axiosInstance } from "./utils";
import Loader from "./loader";
import { BroadcastChannel } from "broadcast-channel";

const App = () => {
  const params = useParams();
  const [reference, setReference] = useState("");
  const [token, setToken] = useState("");
  const [stepUptoken, setStepUptoken] = useState("");
  const [collectionUrl, setCollection] = useState("");
  const [setUpUrl, setStepUp] = useState("");
  const [loading, setLoading] = useState(true);
  const [showDeviceDetails, setShowDeviceDetails] = useState(false);
  const [showStepUp, setShowStepUp] = useState(false);
  const [eventData, setEventData] = useState();

  const location = useLocation();

  const getToken = async (paymentReference) => {
    const response = await axiosInstance.get(
      `${process.env.REACT_APP_BASE_URL}/seerbit-cybersource/v2/device/${paymentReference.sdref}`
    );
    setToken(response.data.accessToken);
    setCollection(response.data.collectionUrl);
    setShowDeviceDetails(true);
  };

  const checkEnrollment = async (paymentReference) => {
    const response = await axiosInstance.get(
      `${process.env.REACT_APP_BASE_URL}/seerbit-cybersource/v2/check-enroll/${paymentReference.sdref}`
    );

    if (
      response.data.status === "PENDING_AUTHENTICATION" &&
      response.data.stepUpUrl
    ) {
      setStepUptoken(response.data.accessToken);
      setStepUp(response.data.stepUpUrl);
      setShowStepUp(true);
    } else {
      window.location.assign(
        `${process.env.REACT_APP_BASE_URL}/seerbit-cybersource/v2/redirect/${paymentReference.sdref}`
      );
      // axiosInstance.get(
      //   `${process.env.REACT_APP_BASE_URL}/seerbit-cybersource/v2/redirect/${paymentReference.sdref}`
      // );
    }
  };

  // console.log(params, params?.sdref.split("&")[0]);

  useEffect(() => {
    // setReference(queryString.parse(location.search));
    setReference(
      params?.sdref.includes("&")
        ? { sdref: params?.sdref?.split("&")[0] }
        : params
    );

    window.addEventListener(
      "message",
      function (event) {
        if (event.origin === "https://centinelapi.cardinalcommerce.com") {
          setTimeout(() => setEventData(JSON.parse(event.data)), 1000);
        }
      },
      false
    );
  }, []);

  useEffect(() => {
    if ((reference.sdref, stepUptoken)) {
      // console.log(reference.sdref, stepUptoken, "token");
      const channel = new BroadcastChannel("app-data");
      channel.addEventListener("message", (event) => {
        // console.log(event, "event");
        // console.log(reference.sdref, stepUptoken, "token")
        if (
          event?.sdref === reference.sdref ||
          event?.data?.sdref === reference.sdref
        ) {
          // window.location.assign(
          //   `${process.env.REACT_APP_BASE_URL}/seerbit-cybersource/v2/redirect/${reference.sdref}`
          // );
        }
      });
    }
  }, [reference.sdref, stepUptoken]);

  useEffect(() => {
    let checkStatus;
    if (reference.sdref && stepUptoken) {
      checkStatus = setInterval(async () => {
        const response = await axiosInstance.get(
          `${process.env.REACT_APP_BASE_URL}/seerbit-cybersource/v2/query/${reference.sdref}`
        );
        if (
          response?.data?.verificationStatus === "AUTHENTICATION_SUCCESSFUL"
        ) {
          clearInterval(checkStatus);
          window.location.assign(
            `${process.env.REACT_APP_BASE_URL}/seerbit-cybersource/v2/redirect/${reference.sdref}`
          );
        } else if (response?.data.posted === true) {
          clearInterval(checkStatus);
          window.location.assign(
            `${process.env.REACT_APP_BASE_URL}/seerbit-cybersource/v2/redirect/${reference.sdref}`
          );
        }
      }, 3000);
    }
  }, [reference.sdref, stepUptoken]);

  useEffect(() => {
    if (reference) {
      getToken(reference);
    }
  }, [reference]);

  useEffect(() => {
    if (eventData?.MessageType === "profile.completed") {
      checkEnrollment(reference);
    }
  }, [eventData]);

  useEffect(() => {
    if (token)
      var cardinalCollectionForm = document.querySelector(
        "#cardinal_collection_form"
      );
    if (cardinalCollectionForm) cardinalCollectionForm.submit();
  }, [token]);

  useEffect(() => {
    if (showStepUp) var stepUpForm = document.querySelector("#step-up-form");
    if (stepUpForm) {
      // Step-Up form exists
      stepUpForm.submit();
      setLoading(false);
    }
  }, [showStepUp]);

  return (
    <>
      {loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            flexDirection: "column",
            background: "white",
          }}
        >
          <Loader />
          {/* <img
            src="https://assets.seerbitapi.com/images/seerbit_logo_type.png"
            style={{ width: "260px", height: "30px" }}
            alt="seerbit"
          /> */}
          <div style={{ fontSize: "20px", marginTop: "28px" }}>
            Redirecting to your bank for authorization.
          </div>
        </div>
      )}
      {showDeviceDetails && token && (
        <>
          <iframe
            id="cardinal_collection_iframe"
            name="collectionIframe"
            title="collectionIframe"
            height="10"
            width="10"
            style={{ display: "none" }}
          ></iframe>
          <form
            id="cardinal_collection_form"
            method="POST"
            target="collectionIframe"
            action={collectionUrl}
          >
            <input
              id="cardinal_collection_form_input"
              type="hidden"
              name="JWT"
              value={token}
            />
          </form>
        </>
      )}
      {showStepUp && stepUptoken && (
        <>
          <iframe
            name="step-up-iframe"
            id="step-up-iframe"
            height="600"
            width="400"
            title="step-up-iframe"
            style={{ width: "100%", height: "100vh", background: "white" }}
          ></iframe>
          <form
            id="step-up-form"
            target="step-up-iframe"
            method="post"
            action={setUpUrl}
          >
            <input type="hidden" name="JWT" value={stepUptoken} />
            <input type="hidden" name="MD" value={reference.sdref} />
          </form>
        </>
      )}
    </>
  );
};

export default App;
